//import "./App.css";
import React, { useEffect, useRef, useState } from "react";
import Sidebar from "./components/Sidebar";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { AboutUs, OurAim, OurVision } from "./pages/AboutUs";
import { Services, ServicesOne, ServicesTwo, ServicesThree } from "./pages/Services";
import { Events, EventsOne, EventsTwo } from "./pages/Results";
import Grid from '@material-ui/core/Grid';
import Contact from "./pages/ContactUs";
import Support from "./pages/Support";
import Generate from "./pages/Generate";
import Results from "./pages/Results";
import Example from "./pages/Example";
import Statistics from "./pages/Statistics";
import Donate from "./pages/Donate";
import About from "./pages/AboutUs";
import FullHeight from "react-full-height";
import Modal from 'react-modal';
import NumInput from "react-numeric-input";


Modal.setAppElement("#root");

function App() {

  /*
  const [dimensions, setDimensions] = useState({ 
    height: window.innerHeight,
    width: window.innerWidth
  });

  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }
    window.addEventListener('resize', handleResize)
  },[]);*/

  return (
    <Router>
      <Sidebar />
      <Switch>
        <Route
                exact
                path="/"
                render={() => {
                    return (
                      <Redirect to="/results" />
                    )
                }}
              />
        <Route path="/generate" exact component={Generate} />
        <Route path="/results" exact component={Results} />
        <Route path="/statistics" exact component={Statistics} />
        <Route path="/about" exact component={About} />
        <Route path="/donate" exact component={Donate} />
      </Switch>
    </Router>

    
  );
};
  
export default App;
