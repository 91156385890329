import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";
import { IconContext } from "react-icons/lib";
import { makeStyles } from '@material-ui/core/styles';
import FullHeight from 'react-full-height';


const Nav = styled.div`
  background: #123456;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
  
const NavIcon = styled(Link)`
  height: 100%;  
  margin-left: 2rem;
  font-size: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
  
const SidebarWrap = styled.div`
  background: #800000;
`;
  
const Sidebar = () => {

  const SidebarLayout = styled.div`
    background: #800000;
    width: 230px;
    position: absolute;
    transition: 350ms;
    overflow: auto;
    z-index: 10;
  `;

  const SidebarNav = styled.nav`
    background: #800000;
    width: 230px;
    height: 100%;
    justify-content: center;
    position: absolute;
    top: 0;
    left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
    transition: 350ms;
    overflow: auto;
    z-index: 10;
  `;

  const [dimensions, setDimensions] = useState({ 
    height: window.innerHeight,
    width: window.innerWidth
  });
  const [sidebar, setSidebar] = useState(true);
  const showSidebar = () => setSidebar(!sidebar);

  /*
  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }
    window.addEventListener('resize', handleResize)
  },[]);*/

  return (

        <SidebarNav sidebar={sidebar}>
          <SidebarWrap >
            {/*<NavIcon to="#">
              <AiIcons.AiOutlineClose onClick={showSidebar} />
            </NavIcon>*/}
            <h1
              style={{ textAlign: "left", 
                     marginLeft: "20px", 
                     marginRight: "20px",
                     fontSize: "40px",
                     paddingTop: "15px",
                     marginBottom: "20px",
                     color: "red" }}>
              LotterySG
            </h1>
            {SidebarData.map((item, index) => {
              return <SubMenu item={item} key={index} />;
            })}
            
            <br/><br/><br/>
            <p
            style={{ textAlign: "left", 
                     marginLeft: "20px", 
                     color: "White" }}>
            Copyright &copy; 2021
          </p>
          </SidebarWrap>
        </SidebarNav>
  );
};
  
export default Sidebar;