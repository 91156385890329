import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import 'reactjs-popup/dist/index.css';
import "./Checkbox.css";
import "./Modal.css";
import NumericInput from 'react-numeric-input';
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import "bootstrap/dist/css/bootstrap.min.css";
import { Fragment } from "react";
import { FaBold } from "react-icons/fa";
import { SearchResults } from "semantic-ui-react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: '#d66a6a',
    minHeight: '-webkit-fill-available',
    position: 'absolute',
  },
  ads:{
    background: 'red',
    height: '100vh',
    minHeight: '-webkit-fill-available',

  },
  winningnumbers: {
    background: '#f5f5dd',
    padding: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  header: {
    background: '#dc0b00',
    padding: theme.spacing(0,2,0,2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  content: {
    background: '#ff6f68',
    padding: theme.spacing(0,2,0,2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  number: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    }
  },
  input: {
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    }
  }
}));

const AboutLayout = styled.div`
    background: #d66a6a;
    width: calc(100vw - 230px);
    height: 100vh;
    position: absolute;
    left: 230px;
    overflow: auto;
    transition: 350ms;
    z-index: 10;
  `;

  const Label = styled.label`
    color: #960018;
    display: inline-block;
    font-weight: 800;
    padding: 5px 5px;
    margin-right: 50px;
    text-align: left;
    vertical-align: middle;
    font-size: 20px;
  `;

  const Button = styled.button`
    background-color: #960018;
    border-radius: 2px;
    color: white;
    padding: 5px;
    margin: 0 10px 0 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-weight: 600;
    font-size: 16px;
  `;

  const colourStyles = {
    control: base => ({
      ...base,
      height: '10px',
      background: "#960018",
      color: 'white',
      border: '3px solid #ccc',
      alignContent: 'center',
      fontSize: 18,
    }),
    singleValue: base => ({
      ...base,
      color: "#fff"
    }),
    input: base => ({
      ...base,
      color: "#fff"
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isFocused
          ? '#e53935'
          : '#960018',
        color: isDisabled
          ? '#ccc'
          : 'white',
        cursor: isDisabled ? 'not-allowed' : 'default',
  
        ':active': {
          ...styles[':active'],
          backgroundColor: 'black',
        },
        fontSize: 18,
      };
    },
  };

  const customDropdownListStyles = {
    option: (provided, state) => ({
      backgroundColor: state.isSelected ? 'red' : '',
      border: `1px solid black`,
      height: '100%',
      textAlign: 'left',
      alignContent: 'center',
      align: 'center',
      alignItems: 'center',
      color: state.isSelected ? 'red' : 'blue',
    }),
    control: (provided) => ({
      ...provided,
      marginTop: '0%',
      textAlign: 'center',
      align: 'center',
      alignContent: 'center',
      alignItems: 'center',
    }),
    input: base => ({
      ...base,
      color: "#000"
    })
  };

  const DropDownContainer = styled("div")`
  width: 10.5em;
`;

const DropDownHeader = styled("div")`
  background: #960018;
  margin-bottom: 0.8em;
  padding: 0.2em 1em 0.2em 1em;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  font-weight: 500;
  font-size: 1.3rem;
  color: white;
`;

const DropDownListContainer = styled("div")`
  position: absolute;
  z-index: 100;
  width: 10.5em;
`;

const DropDownList = styled("ul")`
  padding: 0;
  margin: 0;
  padding-left: 1em;
  background: #960018;
  border: 2px solid #960018;
  box-sizing: border-box;
  color: white;
  font-size: 1.3rem;
  font-weight: 500;
  &:first-child {
    padding-top: 0.8em;
  }
`;

const ListItem = styled("li")`
  list-style: none;
  margin-bottom: 0.8em;
  &:hover {
    color: #fd9e46;
  }
`;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const About = () => {

  const classes = useStyles();
  
  const ref = useRef();



  useEffect(() => {  //Execute only once when first render.

  },[]);

  const copyToClipboard = () => {
  };
  

  return (
    <AboutLayout>

      <Grid container className={classes.root}>
        <Grid item xs={10} style={{marginTop: '50px'}}>
            <Grid container item xs={12} align="center" style={{marginTop: '10px'}}>
              <Grid item xs={1}></Grid>
             
              <Grid item xs={1}></Grid>
            </Grid>
            
            <Grid container item xs={12} align="center" style={{marginTop: '6px'}}>
              <Grid item xs={1}></Grid>
              
              <Grid item xs={1}></Grid>
            </Grid>
            
           

            <Grid container item xs={12}>
              <br/>
              <br/>
            </Grid>


        </Grid>

        <Grid container item xs={2} style={{background: 'red', height: 'auto', overflow: 'auto'}}>
          {/*Vertical Ads Here*/}
        </Grid>
        </Grid>

    </AboutLayout>

  );

};

export default About;