import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import 'reactjs-popup/dist/index.css';
import "./Checkbox.css";
import "./Modal.css";
import NumericInput from 'react-numeric-input';
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import "bootstrap/dist/css/bootstrap.min.css";
import { Fragment } from "react";
import { FaBold } from "react-icons/fa";
import { SearchResults } from "semantic-ui-react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: '#d66a6a',
    minHeight: '-webkit-fill-available',
    position: 'absolute',
  },
  ads:{
    background: 'red',
    height: '100vh',
    minHeight: '-webkit-fill-available',

  },
  winningnumbers: {
    background: '#f5f5dd',
    padding: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  header: {
    background: '#dc0b00',
    padding: theme.spacing(0,2,0,2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  content: {
    background: '#ff6f68',
    padding: theme.spacing(0,2,0,2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  number: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    }
  },
  input: {
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    }
  }
}));

const GenerateLayout = styled.div`
    background: #d66a6a;
    width: calc(100vw - 230px);
    height: 100vh;
    position: absolute;
    left: 230px;
    overflow: auto;
    transition: 350ms;
    z-index: 10;
  `;

  const Label = styled.label`
    color: #960018;
    display: inline-block;
    font-weight: 800;
    padding: 5px 5px;
    margin-right: 50px;
    text-align: left;
    vertical-align: middle;
    font-size: 20px;
  `;

  const Button = styled.button`
    background-color: #960018;
    border-radius: 2px;
    color: white;
    padding: 5px;
    margin: 0 10px 0 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-weight: 600;
    font-size: 16px;
  `;

  const colourStyles = {
    control: base => ({
      ...base,
      height: '10px',
      background: "#960018",
      color: 'white',
      border: '3px solid #ccc',
      alignContent: 'center',
      fontSize: 18,
    }),
    singleValue: base => ({
      ...base,
      color: "#fff"
    }),
    input: base => ({
      ...base,
      color: "#fff"
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isFocused
          ? '#e53935'
          : '#960018',
        color: isDisabled
          ? '#ccc'
          : 'white',
        cursor: isDisabled ? 'not-allowed' : 'default',
  
        ':active': {
          ...styles[':active'],
          backgroundColor: 'black',
        },
        fontSize: 18,
      };
    },
  };

  const customDropdownListStyles = {
    option: (provided, state) => ({
      backgroundColor: state.isSelected ? 'red' : '',
      border: `1px solid black`,
      height: '100%',
      textAlign: 'left',
      alignContent: 'center',
      align: 'center',
      alignItems: 'center',
      color: state.isSelected ? 'red' : 'blue',
    }),
    control: (provided) => ({
      ...provided,
      marginTop: '0%',
      textAlign: 'center',
      align: 'center',
      alignContent: 'center',
      alignItems: 'center',
    }),
    input: base => ({
      ...base,
      color: "#000"
    })
  };

  const DropDownContainer = styled("div")`
  width: 10.5em;
`;

const DropDownHeader = styled("div")`
  background: #960018;
  margin-bottom: 0.8em;
  padding: 0.2em 1em 0.2em 1em;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  font-weight: 500;
  font-size: 1.3rem;
  color: white;
`;

const DropDownListContainer = styled("div")`
  position: absolute;
  z-index: 100;
  width: 10.5em;
`;

const DropDownList = styled("ul")`
  padding: 0;
  margin: 0;
  padding-left: 1em;
  background: #960018;
  border: 2px solid #960018;
  box-sizing: border-box;
  color: white;
  font-size: 1.3rem;
  font-weight: 500;
  &:first-child {
    padding-top: 0.8em;
  }
`;

const ListItem = styled("li")`
  list-style: none;
  margin-bottom: 0.8em;
  &:hover {
    color: #fd9e46;
  }
`;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const Generate = () => {
  const TOTALNUMBER = 49;
  const MIN_SELECTED = 20;

  const options = [
    { value: 6, label: 'Ordinary' },
    { value: 7, label: 'System 7' },
    { value: 8, label: 'System 8' },
    { value: 9, label: 'System 9' },
    { value: 10, label: 'System 10' },
    { value: 11, label: 'System 11' },  
    { value: 12, label: 'System 12' }  
  ]
  
  //const options = ["Ordinary", "System 7", "System 8", "System 9", "System 10", "System 11", "System 12"];

  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [number_of_sets, setNumberOfSets] = useState(1);
  const [dialog_title, setTitile] = useState(null);
  const [dialog_content, setContent] = useState(null);

  const classes = useStyles();
  
  function checkall() {
    for (let i = 1; i <= TOTALNUMBER; i++) {
      document.getElementById("cb"+i.toString().padStart(2,"0")).checked = true;
    }
  };

  function uncheckall() {
    for (let i = 1; i <= TOTALNUMBER; i++) {
      document.getElementById("cb"+i.toString().padStart(2,"0")).checked = false;
    }  
  };

  function checkOdd() {
    for (let i = 1; i <= TOTALNUMBER; i+=2) {
      document.getElementById("cb"+i.toString().padStart(2,"0")).checked = true;
    }  
  };

  function checkEven() {
    for (let i = 2; i <= TOTALNUMBER; i+=2) {
      document.getElementById("cb"+i.toString().padStart(2,"0")).checked = true;
    }  
  };

  function updateSelectedNumbers() {
    var count = 0;
    for (let i = 1; i <= TOTALNUMBER; i++) {
      if(document.getElementById("cb"+i.toString().padStart(2,"0")).checked) {
        count++;
      }
    }
    document.getElementById("selected_label").innerHTML = "Selected: " + count.toString();
  };

  const toggling = () => setIsOpen(!isOpen);
  const onOptionClicked = value => () => {
    setSelectedOption(value);
    setIsOpen(false);
  };
  const ref = useRef();


  useEffect(() => {
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isOpen && ref.current && !ref.current.contains(e.target)) {
        setIsOpen(false)
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [isOpen]);
  
  useEffect(() => {  //Execute only once when first render.
    checkall();
    updateSelectedNumbers();
    setSelectedOption(options[0]);
  },[]);

  function NewlineText(props) {
    const text = props.text;
    var array = text.split('\n');
    var count = 1;
    var list = array.map(function(name){
      if(name != "")
        return <div>{count++}) {name}</div>;
    })
    return list;
  }

  const numbers = [];

  function generated() {
    var title = "";
    var content = "";

    console.log("generated called");
    for (let i = 1; i <= TOTALNUMBER; i++) {
      if(document.getElementById("cb"+i.toString().padStart(2,"0")) != null) {
        if(document.getElementById("cb"+i.toString().padStart(2,"0")).checked) {
          numbers.push(i.toString().padStart(2,"0"));
        }
      }
    }

    if(numbers.length < MIN_SELECTED) {
      title = "Error!"
      content = "Selected Numbers cannot be less than " + MIN_SELECTED + "!";
      console.log("error");
      setTitile(title);
      setContent(content);
      setOpen(true);
      return;
    }

    //const system = Math.max(0,options.indexOf(selectedOption)) + 6;
    var system = selectedOption.value;
    var sets = number_of_sets;

    if(sets > 200) {
      setNumberOfSets(200);
      sets = 200;
    }

    if(sets === 1) {
      title = sets + " set of ";
    } else {
      title = sets + " sets of ";
    }

    if(system === 6) {
      title += "ordinary";
    } else {
      title += "system " + system;
    }
    content = "";

    if(document.getElementById("number_of_sets") !== null) {
      //number_of_sets = document.getElementById("number_of_sets").value;
      for(let i =1; i<=sets; i++) {
        var str = generateRandomSet(numbers,system);
        //str = str.slice(0, -1);
        content += str; //i+") "+str;
        content += "\n";
      }
    }

    console.log("open dialog");
    setTitile(title);
    setContent(content);
    setOpen(true);
    return;
  }


  function generateRandomSet(numbers,system) {
    var gen = [];
    var num = numbers.map((x) => x);

    for(let i = 0; i<system; i++) {
      const max = num.length;
      const rand =  Math.floor(Math.random()*(max));
      gen.push(num[rand]);
      num.splice(rand, 1);
    }

    var results = "";
    gen.sort();
    for(let i=0; i<gen.length; i++) {
      results += gen[i];
      if(i !== (gen.length - 1)) {
        results += ",";
      }
    }
    return results;
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(dialog_content);
  };
  
  var handleChange = (selectedOption) => {
    setSelectedOption(selectedOption); // = selectedOption;    
    console.log('Option selected: ', selectedOption);
  };  

  const handleClose = () => setOpen(false);

  return (
    <GenerateLayout>

      <Grid container className={classes.root}>
        <Grid item xs={10} style={{marginTop: '50px'}}>
            <Grid container item xs={12} align="center" style={{marginTop: '10px'}}>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>01
                  <input type="checkbox" id="cb01" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid>
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>02
                  <input type="checkbox" id="cb02" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid>
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>03
                  <input type="checkbox" id="cb03" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid>
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>04
                  <input type="checkbox" id="cb04" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid>
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>05
                  <input type="checkbox" id="cb05" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid>
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>06
                  <input type="checkbox" id="cb06" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>07
                  <input type="checkbox" id="cb07" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>08
                  <input type="checkbox" id="cb08" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>09
                  <input type="checkbox" id="cb09" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>10
                  <input type="checkbox" id="cb10" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}></Grid>
            </Grid>
            
            <Grid container item xs={12} align="center" style={{marginTop: '6px'}}>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>11
                  <input type="checkbox" id="cb11" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>12
                  <input type="checkbox" id="cb12" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>13
                  <input type="checkbox" id="cb13" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>14
                  <input type="checkbox" id="cb14" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>15
                  <input type="checkbox" id="cb15" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>16
                  <input type="checkbox" id="cb16" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>17
                  <input type="checkbox" id="cb17" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>18
                  <input type="checkbox" id="cb18" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>19
                  <input type="checkbox" id="cb19" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>20
                  <input type="checkbox" id="cb20" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}></Grid>
            </Grid>
            
            <Grid container item xs={12} align="center" style={{marginTop: '6px'}}>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>21
                  <input type="checkbox" id="cb21" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>22
                  <input type="checkbox" id="cb22" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>23
                  <input type="checkbox" id="cb23" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>24
                  <input type="checkbox" id="cb24" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>25
                  <input type="checkbox" id="cb25" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>26
                  <input type="checkbox" id="cb26" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>27
                  <input type="checkbox" id="cb27" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>28
                  <input type="checkbox" id="cb28" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>29
                  <input type="checkbox" id="cb29" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>30
                  <input type="checkbox" id="cb30" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}></Grid>
            </Grid>

            <Grid container item xs={12} align="center" style={{marginTop: '6px'}}>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>31
                  <input type="checkbox" id="cb31" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>32
                  <input type="checkbox" id="cb32" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>33
                  <input type="checkbox" id="cb33" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>34
                  <input type="checkbox" id="cb34" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>35
                  <input type="checkbox" id="cb35" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>36
                  <input type="checkbox" id="cb36" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>37
                  <input type="checkbox" id="cb37" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>38
                  <input type="checkbox" id="cb38" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>39
                  <input type="checkbox" id="cb39" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>40
                  <input type="checkbox" id="cb40" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}></Grid>
            </Grid>

            <Grid container item xs={12} align="center" style={{marginTop: '6px'}}>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>41
                  <input type="checkbox" id="cb41" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>42
                  <input type="checkbox" id="cb42" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>43
                  <input type="checkbox" id="cb43" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>44
                  <input type="checkbox" id="cb44" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>45
                  <input type="checkbox" id="cb45" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>46
                  <input type="checkbox" id="cb46" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>47
                  <input type="checkbox" id="cb47" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>48
                  <input type="checkbox" id="cb48" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={1}>
                <label className="checkbox" align="left" onClick={() => {updateSelectedNumbers()}}>49
                  <input type="checkbox" id="cb49" ></input>
                  <span className="checkmark"></span>
                </label>
              </Grid> 
              <Grid item xs={2}></Grid>
            </Grid>

            <Grid container item xs={12}>
              <br/>
              <br/>
            </Grid>

            <Grid container item xs={12} align="center">
              <Grid item xs={1}></Grid>
              <Grid item xs={4} align="left">
                <Label id="selected_label">Selected: 0</Label>
              </Grid>
              <Grid item xs={6} align="right">
                <Button onClick={() => { checkall(); updateSelectedNumbers() }}>Check All</Button>
                <Button onClick={() => { uncheckall(); updateSelectedNumbers() }}>Uncheck All</Button>
                <Button onClick={() => { uncheckall(); checkOdd(); updateSelectedNumbers() }}>Check Odd</Button>
                <Button onClick={() => { uncheckall(); checkEven(); updateSelectedNumbers() }}>Check Even</Button>
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>

            <Grid container item xs={12}>
              <br/>
              <br/>
            </Grid>

            <Grid container item xs={12} align="center">
              <Grid item xs={1}></Grid>
              <Grid item xs={10} align="left">
                <Grid container item xs={12}>
                  <Grid item xs={2}>
                    <Label>System: </Label>
                  </Grid>
                  <Grid item xs={4} align="center">
                    <Select options={options} value = {selectedOption} onChange={handleChange} autoFocus={false} styles={colourStyles}> </Select>
                    {/*
                    <DropDownContainer>
                      <DropDownHeader onClick={toggling}>{selectedOption || "Ordinary"} </DropDownHeader>
                      {isOpen && (
                        <DropDownListContainer>
                          <DropDownList ref={ref}>
                            {options.map(option => (
                              <ListItem onClick={onOptionClicked(option)} key={Math.random()}>{option}</ListItem>
                            ))}
                          </DropDownList>
                        </DropDownListContainer>
                      )}
                            </DropDownContainer> */}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>

            <Grid container item xs={12}>
              <br/>
            </Grid>

            <Grid container item xs={12} align="center">
              <Grid item xs={1}></Grid>
              <Grid item xs={10} align="left">
                <Grid container item xs={12}>
                  <Grid item>
                    <Label>Number of sets: </Label>
                  </Grid>
                  <Grid item>
                    <NumericInput value={number_of_sets} id="number_of_sets" className="form-control" onChange={setNumberOfSets}
                        initvalue={1}
                        min={1} 
                        max={200} 
                        step={1} 
                        precision={0} 
                        style={{
                          wrap: {
                              background: '#E2E2E2',
                              boxShadow: '0 0 1px 1px #fff inset, 1px 1px 5px -1px #000',
                              padding: '2px 2.26ex 2px 2px',
                              borderRadius: '6px 3px 3px 6px',
                              fontSize: 22
                          },
                          input: {
                              background: '#960018',
                              width: '200px',
                              borderRadius: '4px 2px 2px 4px',
                              color: 'white',
                              padding: '0.1ex 1ex',
                              border: '1px solid #ccc',
                              marginRight: 4,
                              display: 'block',
                              fontWeight: 300,
                          },
                          'input:focus' : {
                              border: '1px inset #69C',
                              outline: 'none'
                          },
                          arrowUp: {
                              borderBottomColor: 'rgba(66, 54, 0, 0.63)'
                          },
                          arrowDown: {
                              borderTopColor: 'rgba(66, 54, 0, 0.63)'
                          }
                      }}/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>

            <Grid container item xs={12}>
              <br/>
              <br/>
              <br/>
            </Grid>

            <Grid container item xs={12} align="center">
              <Grid item xs={1}></Grid>
                <Grid item xs={10} align="left">
                  <Button onClick={() => { generated() }}>Generate!</Button>
                    <Modal show={open} centered>
                      <Modal.Dialog animation={false} onHide={handleClose} >
                        <Modal.Header>
                          <Modal.Title>{dialog_title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <NewlineText text={dialog_content}/>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={handleClose}>
                            Close
                          </Button>
                          <Button variant="primary" onClick={copyToClipboard}>
                            Copy
                          </Button>
                        </Modal.Footer>
                      </Modal.Dialog> 
                    </Modal>
                </Grid>
              <Grid item xs={1}></Grid>
            </Grid>

        </Grid>

        <Grid container item xs={2} style={{background: 'red', height: 'auto', overflow: 'auto'}}>
          {/*Vertical Ads Here*/}
        </Grid>
        </Grid>

    </GenerateLayout>

  );

};

export default Generate;