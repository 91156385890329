import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import {Bar} from 'react-chartjs-2';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { DataGrid } from '@mui/x-data-grid';
import {Circle} from 'react-shapes';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { MuiThemeProvider } from 'material-ui';

const theme = createTheme({
  typography: {
    subtitle1: {
      color: 'black',
      fontStyle: 'bold',
      fontFamily: 'Verdana, sans-serif',
      fontSize: 28,
      fontWeight: 600,
    },
    body1: {
      color: 'white',
      fontStyle: 'bold',
      fontFamily: 'Verdana, sans-serif',
      fontSize: 14,
      fontWeight: 800,
    },
    body2: {
      color: 'black',
      fontStyle: 'bold',
      fontFamily: 'Verdana, sans-serif',
      fontSize: 14,
      fontWeight: 600,
    },
    button: {
      fontStyle: 'italic',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: '#d66a6a',
    minHeight: '-webkit-fill-available',
    position: 'absolute',
  },
  ads:{
    background: 'red',
    height: '100vh',
    minHeight: '-webkit-fill-available',
  },
  header: {
    background: '#dc0b00',
    padding: theme.spacing(0,2,0,2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    color: '#ffffff',
  },
  content: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
    color: '#ffffff',
  },
  oddeventable: {
    background: '#ffedda',
    textAlign: 'center',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '12px',
    color: '#123456',
  }
}));

const freq_marks = [
  {
    value: 10,
    label: '10',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 100,
    label: '100',
  },
  {
    value: 150,
    label: '150',
  },
  {
    value: 200,
    label: '200',
  },
];

const numberlist_marks = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 15,
    label: '15',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 25,
    label: '25',
  },
  {
    value: 30,
    label: '30',
  },
  {
    value: 35,
    label: '35',
  },
  {
    value: 40,
    label: '40',
  },
  {
    value: 45,
    label: '45',
  },
  {
    value: 50,
    label: '50',
  },
];

const oddeven_marks = [
  {
    value: 5,
    label: '5',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 15,
    label: '15',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 25,
    label: '25',
  },
  {
    value: 30,
    label: '30',
  },
  {
    value: 35,
    label: '35',
  },
  {
    value: 40,
    label: '40',
  },
  {
    value: 45,
    label: '45',
  },
  {
    value: 50,
    label: '50',
  },
];



const ExampleLayout = styled.div`
    background: #d66a6a;
    width: calc(100vw - 230px);
    height: 100vh;
    position: absolute;
    left: 230px;
    overflow: auto;
    transition: 350ms;
    z-index: 10;
  `;

/*var freq = [65, 59, 80, 81, 56, 43, 33, 56, 87, 23,
  65, 59, 80, 81, 56, 43, 33, 56, 87, 23,
  65, 59, 80, 81, 56, 43, 33, 56, 87, 23,
  65, 59, 80, 81, 56, 43, 33, 56, 87, 23,
  65, 59, 80, 81, 56, 43, 33, 56, 87];*/

 /*const rows = [
    { id: 1234, drawdate: '01 April 2013', drawresults: '01,02,03,04,05,06,07', odd: 35, even: 15 },
    { id: 1235, drawdate: '01 April 2013', drawresults: '01,02,03,04,05,06,07', odd: 35, even: 15 },
    { id: 1236, drawdate: '01 April 2013', drawresults: '01,02,03,04,05,06,07', odd: 35, even: 15 },
    { id: 1237, drawdate: '01 April 2013', drawresults: '01,02,03,04,05,06,07', odd: 35, even: 15 },
    { id: 1238, drawdate: '01 April 2013', drawresults: '01,02,03,04,05,06,07', odd: 35, even: 15 },
  ];*/

export default function Example() {
  const classes = useStyles();
  const fetch = require('node-fetch'); //https://stackabuse.com/making-http-requests-in-node-js-with-node-fetch
  const [freq_ready, setFreqReady] = useState(false);
  const [numberlist_ready, setNumberListReady] = useState(false);
  const [oddeven_ready, setOddEvenReady] = useState(false);
  const [freq, setFreq] = useState(null);
  const [nod_freq, setNodFreq] = useState(100);
  const [totalnumberlist, setTotalNumberList] = useState(0);
  const [numberlist, setNumberList] = useState(null);
  const [nod_numberlist, setNodNumberList] = useState(7);
  const [oddeven, setOddEven] = useState(null);
  const [nod_oddeven, setNodOddEven] = useState(5);

  const state = {
    labels: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', 
             '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
             '21', '22', '23', '24', '25', '26', '27', '28', '29', '30',
             '31', '32', '33', '34', '35', '36', '37', '38', '39', '40',
             '41', '42', '43', '44', '45', '46', '47', '48', '49'],
    datasets: [
      {
        label: 'Frequency',
        backgroundColor: 'rgba(234,60,83,1)',
        borderColor: 'rgba(0,0,0,1)',
        borderWidth: 2,
        data: freq
      }
    ]
  }

  const columns = [
    { 
      field: 'id', 
      headerName: 'Draw Number', 
      width: 110, 
      headerAlign: 'center', 
      align: 'center', 
      color: '#ffffff', 
      fontWeight: 'bold', 
      headerClassName: classes.oddeventable, 
      accessor: 'notFilterable',
      filterable: false 
    },
    { 
      field: 'drawdate', 
      headerName: 'Draw Date', 
      width: 130, 
      headerAlign: 'center', 
      align: 'center', 
      color: '#ffffff', 
      fontWeight: 'bold', 
      headerClassName: classes.oddeventable, 
      accessor: 'notFilterable',
      filterable: false 
    },
    { 
      field: 'drawresults', 
      headerName: 'Draw Results', 
      width: 150, 
      headerAlign: 'center', 
      align: 'center', 
      color: '#ffffff', 
      fontWeight: 'bold', 
      headerClassName: classes.oddeventable, 
      accessor: 'notFilterable',
      filterable: false  
    },
    {
      field: 'odd',
      headerName: 'Odd',
      type: 'number',
      width: 70,
      headerAlign: 'center',
      headerClassName: classes.oddeventable,
      fontWeight: 'bold',
      align: 'center',
      color: '#ffffff',
    },
    {
      field: 'even',
      headerName: 'Even',
      type: 'number',
      width: 70,
      headerAlign: 'center',
      headerClassName: classes.oddeventable,
      align: 'center',
      color: '#ffffff',
    }
  ];

  ////////////////////////////////////////////////////////
  //// Frequency Request
  ////////////////////////////////////////////////////////

  useEffect(() => {  //Execute only once when first render.
    console.log("fetching list - 1");
    //fetch('http://127.0.0.1:8081/getresultsfreq', requestFreqOptions)
    fetch('https://lotterysg.com/api/getresultsfreq', requestFreqOptions)
    .then(response => response.json())
    .then(data => getFreqResults(data));  
    console.log("fetching list - 2");
  },[nod_freq]);

  const requestFreqOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ numberofdraws: nod_freq })
    };

  function getFreqResults(data) {
      console.log(data);
      //console.log(nod);
  
      setFreq(data);
      setFreqReady(true);
    };

  ////////////////////////////////////////////////////////
  //// Draw Numbers Request
  ////////////////////////////////////////////////////////

  useEffect(() => {  //Execute only once when first render.
    console.log("fetching list - 1");
    //fetch('http://127.0.0.1:8081/getresultsdrawnumbers', requestNumberListOptions)
    fetch('https://lotterysg.com/api/getresultsdrawnumbers', requestNumberListOptions)
    .then(response => response.text())
    .then(data => getNumberListResults(data));  
    console.log("fetching list - 2");
  },[nod_numberlist]);

  const requestNumberListOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ numberofdraws: nod_numberlist })
    };

  function getNumberListResults(data) {
      console.log(data);
      //console.log(nod);
      setTotalNumberList(data.split(",").length);
      setNumberList(data);
      setNumberListReady(true);
    }; 

  ////////////////////////////////////////////////////////
  //// Odd/Even Request
  ////////////////////////////////////////////////////////

  useEffect(() => {  //Execute only once when first render.
    console.log("fetching list - 1");
    //fetch('http://127.0.0.1:8081/getresultsoddeven', requestOddEvenOptions)
    fetch('https://lotterysg.com/api/getresultsoddeven', requestOddEvenOptions)
    .then(response => response.json())
    .then(data => getOddEvenResults(data));  
    console.log("fetching list - 2");
  },[nod_oddeven]);

  const requestOddEvenOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ numberofdraws: nod_oddeven })
    };

  function getOddEvenResults(data) {
    console.log(data);
    setOddEven(data);
    setOddEvenReady(true);
  }; 

  const handleFrequencySliderChange = (event, newValue) => {
    //console.log("New slider value: " + newValue);
    setNodFreq(newValue);
  };

  const handleNumberListSliderChange = (event, newValue) => {
    //console.log("New slider value: " + newValue);
    setNodNumberList(newValue);
  };

  const handleOddEvenSliderChange = (event, newValue) => {
    //console.log("New slider value: " + newValue);
    setNodOddEven(newValue);
  };

  function NewlineText(props) {
    const text = props.text;
    if(text == null) return null;
    var array = text.split('\n');
    var count = 1;
    var list = array.map(function(name){
      if(name != "")
        return <div>[{name}]</div>;
    })
    return list;
  }

  return (
    <ThemeProvider theme={theme}>

    <ExampleLayout>
      <Grid container className={classes.root}>
        <Grid item xs={10} style={{marginTop: '18px'}}>

          <Grid container>
            <Grid item xs={1} />
            <Grid item xs={10}  style={{marginTop: '30px'}}>
              <Accordion defaultExpanded={true} style={{background: '#960018'}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{color: '#ffffff'}}/>}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
                  <Typography className={classes.heading}>Number frequency</Typography>
                </AccordionSummary>
                <AccordionDetails style={{background: '#fa8072'}}>
                  <Grid container>
                    <Grid item xs={1} />
                                        
                    <Grid item xs={10} style={{marginTop: '30px'}}>
                      <Typography id="input-slider" style={{color: '#960018'}} gutterBottom>
                        Number Of Draws
                      </Typography> 
                      <Box>
                          <Slider
                            style={{color: '#960018'}}
                            defaultValue={100}
                            min={10}
                            max={200}
                            step={10}
                            valueLabelDisplay="on"
                            marks={freq_marks}
                            onChange={handleFrequencySliderChange}
                          />
                      </Box>
                    </Grid>
                    <Grid item xs={1} />

                    <Grid item xs={1} />
                    <Grid item xs={10} style={{marginTop: '30px'}}>
                      {freq_ready && <Bar data={state}
                        options={{
                          title:{
                            display:true,
                            text:'Number Frequency',
                            fontSize:20
                          },
                          legend:{
                            display:true,
                            position:'right'
                          },
                          scales:{
                            yAxes: [{
                              ticks: {
                                display: true,
                                labelString: 'Frequency'
                              }
                            }],
                            xAxes: [{
                              ticks: {
                                display: true,
                                labelString: 'Number'
                              }
                            }],
                          }     
                      }}/>}
                    </Grid>
                    <Grid item xs={1} />
                  </Grid>
                  {/*
                  <Typography className={classes.content}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                    sit amet blandit leo lobortis eget.
                </Typography> */}
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={1} />
          </Grid>

          <Grid container>
            <Grid item xs={1} />
            <Grid item xs={10} style={{marginTop: '20px'}}>
              <Accordion style={{background: '#960018'}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{color: '#ffffff'}}/>}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
                  <Typography className={classes.heading}>Numbers appeared in last N draw</Typography>
                </AccordionSummary>
                <AccordionDetails style={{background: '#fa8072'}}>

                  <Grid container>
                    <Grid item xs={1} />
                                        
                    <Grid item xs={10} style={{marginTop: '30px'}}>
                      <Typography id="input-slider" style={{color: '#960018'}} gutterBottom>
                        Number Of Draws
                      </Typography> 
                      <Box>
                          <Slider
                            style={{color: '#960018'}}
                            defaultValue={7}
                            min={1}
                            max={50}
                            step={1}
                            valueLabelDisplay="on"
                            marks={numberlist_marks}
                            onChange={handleNumberListSliderChange}
                          />
                      </Box>
                    </Grid>
                    <Grid item xs={1} />

                    <Grid item xs={1} />
                    <Grid item xs={10} style={{marginTop: '30px'}} zeroMinWidth>
                      {numberlist_ready && 
                        <div style={{display:"flex", flexDirection: "column"}}>
                          <Typography style={{overflowWrap: 'break-word', color: '#630A10', align: 'center'}}>
                            Total: {totalnumberlist}
                          </Typography>
                          <Typography style={{overflowWrap: 'break-word', color: '#630A10', align: 'center'}}>
                            {numberlist}
                          </Typography>
                        </div>
                      }
                    </Grid>
                    <Grid item xs={1} />
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={1} />
          </Grid>

          <Grid container> 
            <Grid item xs={1} />
            <Grid item xs={10} style={{marginTop: '20px'}}>
              <Accordion style={{background: '#960018'}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{color: '#ffffff'}}/>}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
                  <Typography className={classes.heading}>Odd/Even count per draw</Typography>
                </AccordionSummary>
                <AccordionDetails style={{background: '#fa8072'}}>
                <Grid container>
                    <Grid item xs={1} />
                                        
                    <Grid item xs={10} style={{marginTop: '30px'}}>
                      <Typography id="input-slider" style={{color: '#960018'}} gutterBottom>
                        Number Of Draws
                      </Typography> 
                      <Box>
                          <Slider
                            style={{color: '#960018'}}
                            defaultValue={5}
                            min={5}
                            max={50}
                            step={1}
                            valueLabelDisplay="on"
                            marks={oddeven_marks}
                            onChange={handleOddEvenSliderChange}
                          />
                      </Box>
                    </Grid>
                    <Grid item xs={1} />

                    <Grid item xs={1} />
                    <Grid item xs={10} style={{marginTop: '30px'}} align="center" zeroMinWidth>
                      <div style={{ height: 400, width: 532}}>
                      {oddeven_ready && 
                        <DataGrid
                          rows={oddeven}
                          columns={columns}
                          pageSize={5}
                          rowsPerPageOptions={[5]}
                          disableSelectionOnClick
                        />
                      }
                      </div>
                    </Grid>
                    <Grid item xs={1} />
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={1} />
          </Grid>

          <br/>
          <br/>

        </Grid>
        
        <Grid item xs={2} style={{background: 'red', height: 'auto', overflow: 'auto'}}>
          {/*Vertical Ads Here*/}
        </Grid>

      </Grid>

    </ExampleLayout>
    </ThemeProvider>
  );
}