import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Select from 'react-select';
import { Button } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FullHeight from 'react-full-height';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: '#d66a6a',
    minHeight: '-webkit-fill-available',
    position: 'absolute',
  },
  ads:{
    background: 'red',
    height: '100vh',
    minHeight: '-webkit-fill-available',

  },
  winningnumbers: {
    background: '#f5f5dd',
    padding: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  header: {
    background: '#dc0b00',
    padding: theme.spacing(0,2,0,2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  content: {
    background: '#ff6f68',
    padding: theme.spacing(0,2,0,2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
}));

const theme = createTheme({
  typography: {
    subtitle1: {
      color: 'black',
      fontStyle: 'bold',
      fontFamily: 'Verdana, sans-serif',
      fontSize: 28,
      fontWeight: 600,
    },
    body1: {
      color: 'white',
      fontStyle: 'bold',
      fontFamily: 'Verdana, sans-serif',
      fontSize: 14,
      fontWeight: 800,
    },
    body2: {
      color: 'black',
      fontStyle: 'bold',
      fontFamily: 'Verdana, sans-serif',
      fontSize: 14,
      fontWeight: 600,
    },
    button: {
      fontStyle: 'italic',
    },
  },
});


export const Results = () => {

  const ResultsLayout = styled.div`
    background: #d66a6a;
    width: calc(100vw - 230px);
    height: 100vh;
    position: absolute;
    left: 230px;
    overflow: auto;
    transition: 350ms;
    z-index: 10;
  `;
/*
  const Grid = styled.div`
  `;

  const Row = styled.div`
    display: flex;
  `;

  const Col = styled.div`
    flex: ${(props) => props.size};
    align: center;
    justify: center;
    margin-right: 5px;
    margin-left: 5px;
  `;
*/
  const drawNumberBackground = {
    'background': '#f5f5dd',
    'height': '130px',
  };

  const drawNumberStyle = {
    'position': 'static',
    'height': 'auto',
    'background': '#f5f5dd',
    'color': 'black',
    'font-size': '2rem',
    'font-family': 'Helvetica, sans-serif',
    'font-style': 'bold',
    'align': 'center',
    'justify': 'center',
  };

  const [selectedOption, setValue] = useState("null");
  const [options, setOption] = useState([]);
  const [display_draw, setDisplayDraw] = useState("null");
  const [ready, setReady] = useState(false);
  const fetch = require('node-fetch'); //https://stackabuse.com/making-http-requests-in-node-js-with-node-fetch
  const [grp1details, setGrp1Details] = useState([]);
  const [grp2details, setGrp2Details] = useState([]);

  useEffect(() => {  //Execute only once when first render.
    console.log("fetching list - 1");
    //fetch('http://127.0.0.1:8081/getresultslist')
    fetch('https://lotterysg.com/api/getresultslist')
    .then(res => res.text())
    .then(text => getResultsHeaderList(text))
    console.log("fetching list - 2");
  },[]);

  useEffect(() => {
    //fetch('http://127.0.0.1:8081/getsingleresult', requestOptions)
    fetch('https://lotterysg.com/api/getsingleresult', requestOptions)
    .then(response => response.json())
    .then(data => getResults(data));  
  }, [selectedOption]); 

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ drawnumber: selectedOption.value })
    };

  function getResultsHeaderList(body) {
    var myObject = JSON.parse(body);
    myObject.map((item, i) => {
      //console.log(i);
      if(i==0) {
        setValue({ value: item.drawnumber, label: item.drawdate + " [" + item.drawnumber + "]" });
        options.push( { value: item.drawnumber, label: item.drawdate + " [" + item.drawnumber + "]" });
      } else if(i < 100) {
        //setOption(options => [...options, { value: item.drawnumber, label: item.drawdate + " [" + item.drawnumber + "]" }])
        options.push( { value: item.drawnumber, label: item.drawdate + " [" + item.drawnumber + "]" });
      }
    })
  };

  var handleChange = (selectedOption) => {
      setValue(selectedOption); // = selectedOption;    
      console.log('Option selected: ', selectedOption);
      //fetch('http://127.0.0.1:8081/getsingleresult', requestOptions)
      fetch('https://lotterysg.com/getsingleresult', requestOptions)
        .then(response => response.json())
        .then(data => getResults(data));
    };  

  function getResults(data) {
    var myObject = JSON.parse(JSON.stringify(data));
    myObject.map((item, i) => {      
      console.log(item);
      setDisplayDraw(item);
      setReady(true);
    })
  };
  
  function NewlineText(props) {
    const text = props.text;
    var array = text.split('\n');
    var count = 1;
    var list = array.map(function(name){
      if(name != "") {
        if(name.match(/^\d{1,2}[\.]/)) {
          return <div color='grey'>&nbsp;&nbsp;&nbsp;&nbsp;{name}</div>;
        } else {
          return <div>{count++}) {name}</div>;
        }
      }
    })
    return list;
  }

  function onClickPrev() {
      const prev = options.find(element => element.value == selectedOption.value-1);
      console.log(selectedOption.value-1);
      if(prev != null) {
        setValue(prev);
      }
  }

  function onClickNext() {
    const next = options.find(element => Number(element.value) == Number(selectedOption.value)+1);
    console.log(Number(selectedOption.value)+1);
    if(next != null) {
      setValue(next);
    }
  }

  const customDropdownListStyles = {
    option: base => ({
      ...base,
      border: `1px dotted pink`,
      height: '100%',
      textAlign: 'left',
      alignContent: 'center',
      align: 'center',
      alignItems: 'center',
    }),
    control: (provided) => ({
      ...provided,
      marginTop: '0%',
      textAlign: 'center',
      align: 'center',
      alignContent: 'center',
      alignItems: 'center',
    })
  }
  

  const classes = useStyles();
  
  function DropDownListRow() {
    return (
      <React.Fragment>
        <Grid container spacing={5}>
          <Grid item xs={4} align="right">
            <Button labelPosition='left' icon='left chevron' content='Prev' align="center" onClick={onClickPrev}/>
          </Grid>
          <Grid item xs={4}>
            <Select options={options} value = {selectedOption} onChange={handleChange} autoFocus={false} styles={customDropdownListStyles}> </Select>
          </Grid>
          <Grid item xs={4} align="left">
            <Button content='Next' icon='right chevron' labelPosition='right' align="center" onClick={onClickNext}/>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  function WinningNumbersRow() {
    if(display_draw != null) {
    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={2} />

          <Grid item xs={8} className={classes.winningnumbers} align="center">
            <Grid container justifyContent="center" spacing={5}>
                <Grid item align="center">
                  <ThemeProvider theme={theme}>
                    <Typography variant="subtitle1">{display_draw.num1}</Typography>
                  </ThemeProvider>
                </Grid>
                <Grid item align="center">
                  <ThemeProvider theme={theme}>
                    <Typography variant="subtitle1">{display_draw.num2}</Typography>
                  </ThemeProvider>                
                  </Grid>
                <Grid item align="center">
                  <ThemeProvider theme={theme}>
                    <Typography variant="subtitle1">{display_draw.num3}</Typography>
                  </ThemeProvider>                
                  </Grid>
                <Grid item align="center">
                  <ThemeProvider theme={theme}>
                    <Typography variant="subtitle1">{display_draw.num4}</Typography>
                  </ThemeProvider>                
                  </Grid>
                <Grid item align="center">
                  <ThemeProvider theme={theme}>
                    <Typography variant="subtitle1">{display_draw.num5}</Typography>
                  </ThemeProvider>                
                  </Grid>
                <Grid item align="center">
                  <ThemeProvider theme={theme}>
                    <Typography variant="subtitle1">{display_draw.num6}</Typography>
                  </ThemeProvider>                
                  </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} align="center">
                <ThemeProvider theme={theme}>
                  <Typography variant="subtitle1">({display_draw.add})</Typography>
                </ThemeProvider>              
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={2} />
        </Grid>
      </React.Fragment>
    );
    } else {
      console.log("return null");
      return null;
    }
  }

  function WinningAmountHeaderRow() {
    if(display_draw != null) {
    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={2} />

          <Grid item xs={8} className={classes.header}>
          <ThemeProvider theme={theme}>
            <Grid container>
                <Grid item xs={2} align="center">
                    <Typography variant="body1">Group</Typography>
                </Grid>
                <Grid item xs={5} align="center">
                    <Typography variant="body1">Amount</Typography>
                </Grid>
                <Grid item xs={5} align="center">
                    <Typography variant="body1">Shares</Typography>
                </Grid>
            </Grid>
          </ThemeProvider>
          </Grid>

          <Grid item xs={2} />
        </Grid>
      </React.Fragment>
    );
    } else {
      return null;
    }
  }

  const WinningAmountRow = ({group, amount, shares}) => {
    if(display_draw != null) {
    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={2} />

          <Grid item xs={8} className={classes.content}>
          <ThemeProvider theme={theme}>
            <Grid container>
                <Grid item xs={2} align="center">
                    <Typography variant="body2">{group}</Typography>
                </Grid>
                <Grid item xs={5} align="center">
                    <Typography variant="body2">{amount}</Typography>
                </Grid>
                <Grid item xs={5} align="center">
                    <Typography variant="body2">{shares}</Typography>
                </Grid>
            </Grid>
          </ThemeProvider>
          </Grid>

          <Grid item xs={2} />
        </Grid>
      </React.Fragment>
    );
    } else {
      return null;
    }
  }

  const GroupDetailsHeader = ({data}) => {
    if(display_draw != null) {
    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={2} />

          <Grid item xs={8} className={classes.header}>
            <ThemeProvider theme={theme}>
              <Typography variant="body1">{data}</Typography>
            </ThemeProvider>
          </Grid>

          <Grid item xs={2} />
        </Grid>
      </React.Fragment>
    );
    } else {
      return null;
    }
  }

  const GroupDetailsContent = ({data}) => {
    if(display_draw != null) {
    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={2} />

          <Grid item xs={8} className={classes.content}>
            <ThemeProvider theme={theme}>
            {data ? (
              <Typography variant="body2">
                  <NewlineText text={data} /><br/>
              </Typography>
            ) : (
              <Typography variant="body2">- <br/></Typography>
            )}
            </ThemeProvider>
          </Grid>
        </Grid>

        <Grid item xs={2} />
      </React.Fragment>
    );
    } else {
      return null;
    }
  }

  const LoadData = () => {
    if(ready) {
    return (
      <React.Fragment>
              <Grid item xs={12} align="center">
                <br />
              </Grid>
              <Grid item xs={12} align="center">
                <WinningNumbersRow />
              </Grid>
              <Grid item xs={12} align="center">
                <WinningAmountHeaderRow />
              </Grid>
              <Grid item xs={12} align="center">
                {display_draw && 
                  <WinningAmountRow group={"1"} amount={display_draw.g1amount} shares={display_draw.g1shares} />
                }
              </Grid>
              <Grid item xs={12} align="center">
                {display_draw && 
                  <WinningAmountRow group={"2"} amount={display_draw.g2amount} shares={display_draw.g2shares} />
                }
              </Grid>
              <Grid item xs={12} align="center">
                {display_draw && 
                  <WinningAmountRow group={"3"} amount={display_draw.g3amount} shares={display_draw.g3shares} />
                }
              </Grid>
              <Grid item xs={12} align="center">
                {display_draw && 
                  <WinningAmountRow group={"4"} amount={display_draw.g4amount} shares={display_draw.g4shares} />
                }
              </Grid>
              <Grid item xs={12} align="center">
                {display_draw && 
                  <WinningAmountRow group={"5"} amount={display_draw.g5amount} shares={display_draw.g5shares} />
                }
              </Grid>
              <Grid item xs={12} align="center">
                {display_draw && 
                  <WinningAmountRow group={"6"} amount={display_draw.g6amount} shares={display_draw.g6shares} />
                }
              </Grid>
              <Grid item xs={12} align="center">
                {display_draw && 
                  <WinningAmountRow group={"7"} amount={display_draw.g7amount} shares={display_draw.g7shares} />
                }
              </Grid>
              <Grid item xs={12} align="left">
                {display_draw && 
                  <GroupDetailsHeader data={display_draw.g1detailsheader} />
                }
              </Grid>
              <Grid item xs={12} align="left">
                {display_draw && 
                  <GroupDetailsContent data={display_draw.g1detailsdata} />
                }
              </Grid>
              <Grid item xs={12} align="left">
                {display_draw && 

                  <GroupDetailsHeader data={display_draw.g2detailsheader} />
                }
              </Grid>
              <Grid item xs={12} align="left">
                {display_draw && 
                  <GroupDetailsContent data={display_draw.g2detailsdata} />
                }
              </Grid> 
      </React.Fragment>
    );
    } else {
      return null;
    }
  }

  return (
    <ThemeProvider theme={theme}>
    <ResultsLayout>
      <Grid container className={classes.root}>
        <Grid item xs={10} style={{marginTop: '18px'}}>
          <Grid container spacing={0}>
            <Grid item xs={12} align="center">
              <DropDownListRow />
            </Grid>
            <LoadData />
          </Grid>
        </Grid>

        <Grid item xs={2} style={{background: 'red', height: 'auto', overflow: 'auto'}}>
          {/*Vertical Ads Here*/}
        </Grid>

      </Grid>
    </ResultsLayout>
    </ThemeProvider>
  );
};
  
export default Results;