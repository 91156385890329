import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as GiIcons from "react-icons/gi";
import * as CgIcons from "react-icons/cg";
import * as FcIcons from "react-icons/fc";

export const SidebarData = [
  {
    title: "Generate",
    path: "/generate",
    icon: <GiIcons.GiRegeneration />,
  },
  {
    title: "Results",
    path: "/results",
    icon: <CgIcons.CgNotes />,
  },
  {
    title: "Statistics",
    path: "/statistics",
    icon: <FcIcons.FcStatistics />,
  },
  /*{
    title: "Statistics",
    path: "/statistics",
    icon: <FcIcons.FcStatistics />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  
    subNav: [
      {
        title: "Number Frequency",
        path: "/about-us/aim",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Numbers Appear in Previous Draw",
        path: "/about-us/vision",
        icon: <IoIcons.IoIosPaper />,
      },
    ],
  },*/
  /*
  {
    title: "About Us",
    path: "/about",
    icon: <FcIcons.FcAbout />,
  },*/
  {
    title: "Donate",
    path: "/donate",
    icon: <FaIcons.FaDonate />,
  },
];